
import { defineComponent } from '@nuxtjs/composition-api'
import { SvoiButtonLink } from '@svoi-ui/components/button'
import { useDevices } from '~/utils/use-devices'

export default defineComponent({
  name: 'ContestMainBanner',
  components: {
    SvoiButtonLink
  },
  setup() {
    const { isMobile } = useDevices()
    return { isMobile }
  }
})
